<template>
  <v-row align-content="center">
    <v-col class="py-0" md="6">
      <v-row>
        <v-col class="white px-12" md="11" style="height: 100vh">
          <v-spacer class="py-16"></v-spacer>
          <div class="px-8">
            <v-row class="mb-4" justify="center">
              <v-col sm="6" lg="4">
                <v-img src="@/assets/images/link_sent.png"></v-img>
              </v-col>
            </v-row>
            <div class="headline mb-6 text-center">
              Password berhasil diperbarui
            </div>
            <div class="subtitle-1 text-center">
              Perubahan password telah berhasil dilakukan, sekarang anda dapat
              kembali masuk untuk mengakses akun anda
            </div>
            <v-spacer class="py-2"></v-spacer>
            <v-row>
              <v-col>
                <router-link :to="{ name: 'login' }">
                  <v-btn large elevation="0" color="primary" block>
                    Masuk ke akun anda
                  </v-btn>
                </router-link>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "password-changed",
};
</script>

<style>
</style>