<template>
  <attachment-layout
    type="audio"
    :src="aud || {}"
    :selected="file[tab] || {}"
    :disabled="disabled"
    :saving="saving"
    :tab="1"
    :show.sync="show"
    :error="errors.message"
    :readonly="isReadOnly"
    @set="setAttachment"
    @open="fetchRecents"
    @close="close"
  >
    <template v-if="aud.with_media === true" slot="preview">
      <v-card class="mb-4" outlined>
        <v-card-text class="pa-0 overflow-hidden">
          <audio-player :src="aud.media.public_url || src.media.url" elevation="0">
            <template v-if="!disabled" slot="append" slot-scope="{ on, attrs }">
              <div class="d-flex" v-on="on" v-bind="attrs">
                <v-btn
                  class="my-auto"
                  color="white"
                  small
                  fab
                  elevation="0"
                  @click="fetchRecents"
                >
                  <v-icon>$replace</v-icon>
                </v-btn>
                <v-btn
                  class="my-auto"
                  color="white"
                  small
                  fab
                  elevation="0"
                  @click="$emit('update', { with_media: false, media: {} });aud.with_media=false"
                >
                  <v-icon>$delete</v-icon>
                </v-btn>
              </div>
            </template>
          </audio-player>
        </v-card-text>
      </v-card>
    </template>
    <template slot="tabs">
      <v-tabs v-model="tab" background-color="transparent">
        <v-tab class="text-capitalize"> Riwayat </v-tab>
        <v-tab class="text-capitalize"> Dari komputer </v-tab>
      </v-tabs>
    </template>
    <template slot="tab-items">
      <v-tabs-items class="transparent fill-height" v-model="tab">
        <v-tab-item>
          <v-card-text>
            <v-row>
              <v-col class="py-2">
                <v-list nav color="background" flat>
                  <v-list-item-group mandatory v-model="selected">
                    <v-list-item
                      class="px-0 mb-2"
                      v-for="recent in recents"
                      :key="recent.id"
                    >
                      <v-col class="pa-0">
                        <v-card
                          outlined
                          style="
                            box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.1) !important;
                          "
                          :color="recent == recents[selected] ? 'primary' : ''"
                        >
                          <v-card-text class="pa-0">
                            <audio-player
                              class="px-2"
                              ref="players"
                              elevation="0"
                              playlist="recent"
                              :controller="false"
                              :src="recent.public_url"
                              @play="play"
                              @mousedown.stop
                              @click.stop
                            ></audio-player>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-list-item>
                  </v-list-item-group>

                  <div class="text-center mt-4">
                    <v-skeleton-loader
                      v-if="loading"
                      type="text"
                    ></v-skeleton-loader>
                    <div v-else class="mb-2">
                      Showing {{ recents.length }} from {{ recent.total }}
                    </div>
                    <v-btn
                      color="primary"
                      small
                      elevation="0"
                      @click="fetchRecents"
                    >
                      More
                    </v-btn>
                  </div>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>
        <v-tab-item
          class="fill-height"
          style="position: relative"
          @dragover.prevent="draging = true"
          @dragleave="draging = false"
          @drop.prevent="setFile"
        >
          <v-card-text class="fill-height d-flex">
            <v-overlay
              absolute
              :value="true"
              :z-index="draging ? 5 : -1"
              :opacity="draging ? 0.46 : 0"
            />

            <input
              hidden
              ref="file"
              type="file"
              accept="audio/*"
              @change="setFile"
            />
            <v-col v-if="_.isEmpty(file[1])" class="text-center my-auto">
              <div class="mb-8">
                <v-btn
                  color="primary"
                  elevation="0"
                  @click="$refs.file.click()"
                >
                  Pilih berkas
                </v-btn>
              </div>
              <div v-if="!draging" class="subtitle-1 mb-4">
                Atau tarik file ke sini
              </div>
              <div v-else class="subtitle-1 mb-4">Lepas file di sini</div>
            </v-col>

            <v-col v-else class="mt-1">
              <v-card
                outlined
                color="primary"
                style="box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.1) !important"
              >
                <v-card-text class="pa-0 overflow-hidden">
                  <audio-player
                    rounded="0"
                    ref="player"
                    elevation="0"
                    :src="file[1].url"
                  >
                    <template v-slot:append="{ on, attrs }">
                      <div class="d-flex" v-on="on" v-bind="attrs">
                        <v-btn
                          class="my-auto px-2"
                          color="white"
                          x-small
                          fab
                          elevation="0"
                          @click="$refs.file.click()"
                        >
                          <v-icon>$replace</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </audio-player>
                </v-card-text>
              </v-card>
            </v-col>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </attachment-layout>
</template>

<script>
import AudioPlayer from "@/views/_inc/media/AudioPlayer";
import AttachmentLayout from "./AttachmentLayout.vue";

export default {
  name: "audio-attachment",
  components: {
    AudioPlayer,
    AttachmentLayout,
  },
  props: {
    disabled: { type: Boolean, default: false },
    src: { type: Array },
  },
  data() {
    return {
      loading: false,
      saving: false,
      show: false,
      tab: 1,
      file: [],
      aud: {},
      selected: null,
      recents: [],
      draging: false,
      errors: {},
      recent: {},
    };
  },
  computed: {
    isReadOnly() {
      return this.tab == 0 || this.loading || this.saving;
    },
  },
  created() {
    if (this.src != '') {
      this.fetchDetail(this.src)
    }
  //   if (this.src.media && Number(this.src.media.id)) {
  //     this.$store.dispatch("media/detail", this.src.media.id).then((res) => {
  //       let file = { with_media: true, media: res.data };
  //       this.$emit("update", file);
  //     });
  //   }
  },
  methods: {
    fetchDetail(id){
      this.$store.dispatch("media/detail", id).then((res) => {
        let file = { with_media: true, id:res.data.id, media: res.data };
        this.aud=file;
        this.$emit("update", file);
      });
    },
    fetchRecents() {
      this.loading = true;
      let page = 1 + this.recent.page || 0;
      
      this.$store
        .dispatch("media/list", { limit: 5, type: "audio", page, sort: 'created_at', dir: 'desc' })
        .then((res) => {
          this.loading = false;
          this.recent = res.data;
          this.recents.push(...this.recent.list);
        });
      this.show = true;
    },
    setFile($event) {
      let vm = this;
      let src = {
        url: "",
        title: "",
        alt: "",
        description: "",
        caption: "",
      };

      switch (vm.tab) {
        case 0:
          Object.assign(src, this.recents[$event]);
          vm.$set(vm.file, vm.tab, src);
          break;
        case 1:
          if (this.draging) {
            this.draging = false;
            src.url = URL.createObjectURL($event.dataTransfer.files[0]);
            src.title = $event.dataTransfer.files[0].name;
            src.alt = $event.dataTransfer.files[0].name;
            src.file = $event.dataTransfer.files[0];
          } else {
            src.url = URL.createObjectURL($event.target.files[0]);
            src.title = $event.target.files[0].name;
            src.alt = $event.target.files[0].name;
            src.file = $event.target.files[0];
          }
          src.source = "upload";

          vm.$set(vm.file, vm.tab, src);
          break;
        default:
          break;
      }
    },
    play() {
      // FIXME: Bentar akan diubah
      if (Array.isArray(this.$refs.players)) {
        this.$refs.players
          .filter((player) => player.playlist == "recent")
          .forEach((player) => {
            player.pause();
          });
      }
    },
    pause() {
      if (
        Array.isArray(this.$refs.players) &&
        this.$refs.players !== undefined
      ) {
        this.$refs.players.forEach((player) => {
          player.pause();
          player.current = 0;
        });
      }

      if (this.$refs.player !== undefined) {
        this.$refs.player.pause();
        this.$refs.player.current = 0;
      }
    },
    close() {
      this.pause();
      this.$delete(this.file, 1);
      this.recents = [];
      this.recent = {};
      this.selected = null;
      this.show = false;
    },
    async setAttachment(file) {
      if (this.tab) {
        this.saving = true;
        let formData = new FormData()
        formData.append('source',file.source)
        formData.append('alt',file.alt)
        formData.append('caption', file.caption)
        formData.append('description', file.description)
        formData.append('file', file.file)
        formData.append('title',`${file.title}`)
        formData.append('url', file.url)
        this.$store
          .dispatch("media/create", formData)
          .then((res) => {
            res.data.url = res.data.public_url
            file = { with_media: true, id:res.data.id, media: res.data };
            this.saving = false;
            this.$emit("update", file);
            this.fetchDetail(file.id)
            this.close();
          })
          .catch((error) => {
            this.errors.message = error.message;
            this.saving = false;
          });
      } else {
        file = { with_media: !this._.isEmpty(file), id:file.id, media: file };
        this.$emit("update", file);
        this.fetchDetail(file.id)
        this.close();
      }
    },
  },
  watch: {
    tab() {
      if (this.tab == 0) this.setFile(this.selected);
      this.pause();
    },
    selected() {
      if (this.tab == 0) this.setFile(this.selected);
    },
    show() {
      if (this.show == false) {
        this.pause();
      }
    },
  },
};
</script>

<style>
@media only screen and (min-width: 960px) {
  .v-application .fill-height-md {
    height: 100% !important;
  }
}
</style>