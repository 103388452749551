var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"transparent",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.data.list,"items-per-page":_vm.data.limit,"page":_vm.data.page,"item-key":'id',"mobile-breakpoint":0,"server-items-length":_vm.data.total,"loading-text":"Loading... Please wait","footer-props":{
    'items-per-page-text':'Row per page : '
  }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.data, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.data, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.data, "page", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('tr',{staticClass:"flat-b-1 rounded-lg"},[_c('td',{staticClass:"white text-capitalize",domProps:{"textContent":_vm._s(item.license)}}),_c('td',{staticClass:"white text-capitalize",staticStyle:{"width":"30%"},domProps:{"textContent":_vm._s(item.member.fullname)}}),_c('td',{staticClass:"white text-capitalize",domProps:{"textContent":_vm._s(item.member.phone)}}),_c('td',{staticClass:"white text-capitalize"},[_vm._v(_vm._s(item.results.progress)+"%")]),_c('td',{staticClass:"white rounded-r-lg",staticStyle:{"width":"5%"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-btn',{attrs:{"title":"Sertifikat Kompetensi","to":{ name: 'certificate-graduation', params: { id: item.license } },"target":"_blank","icon":""}},[_c('v-icon',[_vm._v("ri-medal-2-line")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"title":"more","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("ri-more-line")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('detail', item)}}},[_c('v-list-item-title',[_vm._v("Detail Aktivitas")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('voucher', item)}}},[_c('v-list-item-title',[_vm._v("Detail Voucher")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('change', item.member.id)}}},[_c('v-list-item-title',[_vm._v("Edit User")])],1)],1)],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }