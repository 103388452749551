<template>
  <v-row>
    <v-col cols="12" md="6">
      <div class="font-weight-medium mb-2">
        <span> Estimasi Durasi (Menit)</span>
      </div>
      <v-text-field
        v-model="data.estimated_duration"
        type="number"
        min="0"
        placeholder="0"
      ></v-text-field>
    </v-col>
  </v-row>
</template>
<script>
import model from "../_mixins/model";
export default {
  mixins: [model],
};
</script>
