import axios from "axios";
import url from "../store/api";

const SET_LIST = "SET_LIST";

export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  getters: {},
  mutations: {
    SET_LIST: (state, data) => (state.list = Array.isArray(data) ? data : []),
  },
  actions: {
    async get(context, payload) {
      const response = await axios
        .get(url.packet_list(payload), { params: payload.params })
        .then((res) => res.data);
      context.commit(SET_LIST, response.data.list)

      return response;
    },
    async create(context, payload) {
      const data = payload;
      const response = await axios.post(url.packet_create(payload), data);
      await context.dispatch('get', payload)
      return response;
    },
    async delete(context, payload) {
      const data = payload;
      const response = await axios.delete(url.packet_delete(payload), data);
      await context.dispatch('get', payload)
      return response;
    },
  },
};
