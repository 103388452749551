<template>
  <v-card elevation="0" rounded="lg" outlined>
    <v-form ref="form" v-model="valid" @submit.prevent="save">
      <v-card-text class="px-8">
        <v-row>
          <v-col cols="12">
            <p class="mb-2">Password saat ini</p>
            <v-text-field
              v-model="old_password"
              type="password"
              :rules="[...rules('Password saat ini', 'required')]"
              filled
              required
              outlined
              autofocus
              hide-details="auto"
              placeholder="Masukan password saat ini"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <p class="mb-2">Password baru</p>
            <v-text-field
              v-model="new_password"
              type="password"
              :rules="[...rules('Password baru', 'required')]"
              filled
              required
              outlined
              autofocus
              hide-details="auto"
              placeholder="Masukan password baru"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <p class="mb-2">Verifikasi password baru</p>
            <v-text-field
              v-model="new_password_confirmation"
              type="password"
              :rules="[...rules('Verifikasi password baru', 'required')]"
              filled
              required
              outlined
              autofocus
              hide-details="auto"
              placeholder="Masukan kembali password baru"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="background pa-4">
        <v-spacer></v-spacer>
        <v-btn
          large
          color="primary"
          :loading="saving"
          type="submit"
          @click="save()"
          elevation="0"
        >
          Simpan Perubahan
        </v-btn>
      </v-card-actions>
    </v-form>
    <v-snackbar top v-model="show_message">
      <div v-html="message"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="show_message = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import url from "../../store/api";
import validationMixin from "../_mixins/validation";

export default {
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      show_message: false,
      message: "",
      saving: false,
      old_password: "",
      new_password: "",
      new_password_confirmation: "",
    };
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        this.errors = null;
        this.$http
          .put(
            url.admin_update_password,
            {
              current_password: this.old_password,
              new_password: this.new_password,
              conf_new_password: this.new_password_confirmation,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            this.saving = false;
            this.show_message = true;
            this.$refs.form.reset();
            this.message = "Password berhasil diubah";
          })
          .catch((error) => {
            this.saving = false;
            this.show_message = true;
            this.new_password = this.new_password_confirmation = "";
            this.message = "Password gagal diubah <br>";
            if (error.response.data) {
              this.message += error.response.data.message;
            }
            console.error(error);
          });
      }
    },
  },
};
</script>

<style lang="">
</style>