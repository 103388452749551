<template>
  <v-alert colored-border border="top" color="color" elevation="2">
    <p class="text-center py-16">
      <img src="@/assets/images/data_empty.png" />
    </p>
    <h1 class="text-center">{{ message }}</h1>
  </v-alert>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "Error 404 - Page not found"
    }
  }
}
</script>