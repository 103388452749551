<template>
  <v-row>
    <tna-form></tna-form>
  </v-row>
</template>
<script>
import TnaForm from "./components/AddAct";

export default {
  components: { TnaForm },
  created() {
    this.$emit("page-changed", 1, {
      title: "Topik & Aktifitas",
      link:  this.$route.path,
    });
    this.$emit("page-changed", 2, {
      title: "Tambah Topik & Aktivitas",
      link: this.$route.path,
    });
  },
};
</script>