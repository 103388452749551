export default {
  model: { prop: "value", event: "change" },
  props: { value: { type: Object, default: () => ({}) } },
  computed: {
    data: function() {
      return this.value;
    },
  },
  watch: {
    data: {
      handler: function(val) {
        this.$emit("change", val);
      },
      deep: true,
    },
  },
};
