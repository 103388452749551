<template>
  <v-row align-content="center">
    <v-col class="py-0" md="6">
      <v-row>
        <v-col class="white px-12" md="11" style="height: 100vh">
          <v-spacer class="py-16"></v-spacer>
          <transition name="fade">
            <v-form v-if="show" class="px-8" @submit.prevent="submit">
              <div class="headline mb-4">New password</div>
              <div class="subtitle-1 mb-3">
                Password harus mengandung sedikitnya 7 huruf dan 1 angka
              </div>
              <v-row>
                <v-col>
                  <div class="subtitle-2 mb-2">Password baru</div>
                  <v-text-field
                    filled
                    v-model="password"
                    type="password"
                    placeholder="Masukkan password baru anda"
                    required
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-spacer class="py-2"></v-spacer>
              <v-row>
                <v-col>
                  <v-btn
                    large
                    elevation="0"
                    type="submit"
                    color="primary"
                    :loading="submiting"
                    block
                  >
                    Reset password
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <div v-else class="px-8">
              <div class="headline mb-4">Hello</div>
              <div class="subtitle-1 mb-2">
                Permohonan memperbarui password telah dibuat. Jika anda yang
                membuat permohonan ini, harap klik tombol di bawah ini.
              </div>
              <v-spacer class="pb-2"></v-spacer>
              <v-row>
                <v-col>
                  <v-btn
                    large
                    elevation="0"
                    color="primary"
                    :loading="submiting"
                    block
                    @click="show = true"
                  >
                    Reset password
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </transition>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "forgot-reset",
  data() {
    return {
      errors: [],
      password: "",
      submiting: false,
      show: false,
    };
  },
  methods: {
    submit() {
      this.submiting = true;
      setTimeout(() => {
        this.$router.push("/password-changed");
        this.sent = true;
        this.submiting = false;
      }, 2000);
    },
    hasError(input) {
      return this._.has(this.errors, input);
    },
  },
};
</script>

<style>
</style>