var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"transparent",attrs:{"headers":_vm.headers,"item-key":'id',"items":_vm.data.listPublish,"items-per-page":_vm.data.perPage,"page":_vm.data.pagePublish,"mobile-breakpoint":0,"loading-text":"Loading... Please wait!","footer-props":{
    'items-per-page-text':'Row per page : '
  }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.data, "perPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.data, "perPage", $event)},"update:page":function($event){return _vm.$set(_vm.data, "pagePublish", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('tr',{staticClass:"flat-b-1 rounded-lg"},[_c('td',{staticClass:"white rounded-l-lg",staticStyle:{"width":"50%"}},[_c('router-link',{staticClass:"color--text font-weight-bold text-capitalize",attrs:{"to":{ name: 'program-detail', params: { id: item.id } }},domProps:{"textContent":_vm._s(item.title)}})],1),_c('td',{staticClass:"white text-capitalize",domProps:{"textContent":_vm._s(item.learning_targets.studies.title)}}),_c('td',{staticClass:"white text-capitalize primary--text text-decoration-underline"},[_c('router-link',{attrs:{"to":{name: 'list-user', params: { id: item.id }}}},[_vm._v(_vm._s(item.total_participants))])],1),_c('td',{staticClass:"white text-capitalize"},[_c('v-chip',{staticClass:"pa-1 text-capitalize",attrs:{"ripple":false,"color":item.status == 'draft' ? 'default' : 'success',"outlined":"","label":"","small":""},domProps:{"textContent":_vm._s(item.status)}})],1),_c('td',{staticClass:"white rounded-r-lg",staticStyle:{"width":"5%"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-btn',{attrs:{"title":"Publish","disabled":item.status == 'publish',"icon":""},on:{"click":function($event){return _vm.$emit('update', item)}}},[_c('v-icon',[_vm._v("$publish")])],1),_c('v-btn',{attrs:{"title":"Archive","disabled":item.status == 'archive',"icon":""},on:{"click":function($event){return _vm.$emit('update', item)}}},[_c('v-icon',[_vm._v("$archive")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"title":"more","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("ri-more-line")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":{ name: 'edit-program', params: { id: item.id } }}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('remove', item)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }