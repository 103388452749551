<template>
  <v-row>
    <v-col cols="12">
      <v-alert type="warning" text outlined>
        Format file yang didukung adalah mp4 ukuran maks: 100 MB.
      </v-alert>
    </v-col>
    <v-col cols="12">
      <div class="mb-2"></div>
      <div class="helper"></div>
      <div class="drop display-inline text-center" @dragover.prevent @drop="onDrop2">
        <input hidden ref="file" type="file" @change="onChange" accept="video/mp4" />
        <div v-if="file && file.type" class="fill-height pa-4">
          <video :src="file.url" width="320" height="240" controls></video>
          <div>
            <div class="btn-float">
              <v-btn small fab color="white" @click="$refs.file.click()">
                <v-icon dark>
                  ri-arrow-go-back-line
                </v-icon>
              </v-btn>
              <v-btn small fab color="white" @click="$delete(data, 'file')">
                <v-icon dark>
                  ri-delete-bin-7-line
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div v-else class="hidden display-inline align-center py-4" v-bind:class="{ image2: true }">
          <p class="mb-2 pt-8"><v-icon large>ri-video-fill</v-icon></p>
          <p class="mb-0 subtitle-2">Tarik dan letakan video di sini</p>
          <p class="mb-2 subtitle-2">atau</p>
          <label class="btn-img display-inline text-center">
            Pilih Video
            <input type="file" name="image2" @change="onChange2" accept="video/mp4" />
          </label>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span>Deskripsi Konten</span>
      </div>
      <vue-editor
        id="editor3"
        :editor-toolbar="customToolbar"
        v-model="data.description"
        placeholder="Masukan deskripsi konten"
        useCustomImageHandler
        @image-added="handleImageAdded"
      ></vue-editor>
    </v-col>
    <v-snackbar 
      top 
      v-model="snackbar.state"
      color="primary"
      outlined
      :timeout="timeout"
    >
      <!-- :timeout="timeout" -->
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>
<script>
import { VueEditor } from "vue2-editor";
import model from "../_mixins/model";

export default {
  components: { VueEditor },
  mixins: [model],
  data() {
    return {
      image2: "",
      content: {},

      snackbar: {
      state: false,
      text: "",
    },
    timeout:5000,
    
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["image"]
      ],
    };
  },
  computed: {
    file() {
      if (this.data.file instanceof File) {
        const file = this.data.file;
        const type = "video";
        const url = URL.createObjectURL(file);
        return { url, type };
      } else {
        return this.data.file;
      }
    },
  },
  methods: {
    handleUpload(progress) {
      console.log(progress);
    },
    onDrop2: function(e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.createFile(files[0]);
    },
    onChange() {
      this.$set(this.data, "file", this.$refs.file.files[0]);
      this.$set(this.data.file, "src", URL.createObjectURL(this.data.file));
    },
    onChange2(e) {
      var files = e.target.files[0];
      this.image2 = URL.createObjectURL(files);
      this.$set(this.data, "file", e.target.files[0]);
      // this.createFile(files[0]);
      // this.uploadCover(files[0]);
    },
    createFile2(file) {
      // if (!file.type.match('image.*')) {
      //   alert('Select an image');
      //   return;
      // }
      // var img = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = function(e) {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    uploadCover2(file) {
      var formData = new FormData();
      let a = file;
      console.log("img", a);

      formData.append("file", file, "fasilitator.jpg");
      formData.append("source", "upload");
      formData.append("title", "fasilitator");

      console.log("result", formData);

      this.$store
        .dispatch("media/create", formData)
        .then((res) => {
          if (res.status == 200) {
            this.content.picture.id = res.data.id;
            // console.log('result', this.id_cover)
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    removeFile2() {
      this.image2 = "";
    },
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      var formData = new FormData();
      // formData.append("image", file);

      formData.append("file", file);
      formData.append("source", "upload");
      formData.append("title", "image video");
      // console.log('result',formData)

      this.$store
        .dispatch("media/create", formData)
        .then((res) => {
          this.snackbar.text = 'Sukses Upload Gambar';
          this.snackbar.state = true;
          // console.log('data image',res)
          if (res.status == 200) {
            const url= res.data.public_url;
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.snackbar.text = error.response.data.message;
          this.snackbar.state = true;
        });

    },
  },
};
</script>
