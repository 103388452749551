<template>
  <v-row>
    <v-col
      v-for="(option, index) in options"
      :key="index"
      cols="12"
      class="pb-0"
      style="position: relative"
      @mouseover="$set(option, 'showDelete', true)"
      @mouseleave="$set(option, 'showDelete', false)"
    >
      <v-card outlined class="mr-16">
        <v-col>
          <v-row no-gutters>
            <v-text-field
              :disabled="readonly"
              background-color="white"
              :outlined="false"
              class="mr-4 mt-0 pt-0"
              v-model="option.text"
              placeholder="Masukkan opsi jawaban"
            ></v-text-field>
            <v-radio-group v-model="correct_answer" class="my-auto pt-0">
              <v-radio :disabled="readonly" :value="option.text"></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
        <div class="col-md-8">
          <p class="mb-2">Attachment Image</p>
          <image-attachment
            v-if="isMediaSet('image')"
              :disabled="isDisabled"
              class="d-inline-block"
              :src="option.media"
              @update="
                $set(option, 'media',[
                  ...$event.id,
                ])
              "
            />
            <span v-if="isDisabled && _.isEmpty(option.media)">
              {{ "No media" }}
            </span>
        </div>
      </v-card>
      <v-btn v-if="!readonly && option.showDelete" absolute icon top right @click="options.splice(index, 1)"
        ><v-icon>$delete</v-icon></v-btn
      >
    </v-col>
    <v-col cols="12">
      <v-btn text @click="options.push({})">+ tambah opsi jawaban</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import model from "../_mixins/model";
import ImageAttachment from "../../../_inc/modal/ImageAttachment.vue";

export default {
  mixins: [model],
  props: {
    readonly: Boolean
  },
  components: {
    ImageAttachment
  },
  data: () => ({
    options: {
      text:'',
      media:[],
    },
    correct_answer: [],
  }),
  watch: {
    options: {
      handler(val) {
        this.$set(this.data, "options", val);
      },
      deep: true,
    },
    correct_answer: {
      handler(val) {
        if (val) {
          this.$set(this.data, "correct_answer", [val]);
        }
      },
      deep: true,
    },
  },
  computed: {
    isDisabled() {
      return !!(this.disabled || this.loading);
    },
  },
  methods: {
    isMediaSet(type) {
      let disabled = this.disabled;
      let notset =
        this.options.media && this.options.media.url;
      let isset =
        (this.options.media && this.options.media.url) &&
        (this.options.media && this.options.media.type == type)
      return !disabled && !notset || isset;
    },
  },
  created() {
    this.options = this.data?.options ? this.data.options : [{}];
    this.correct_answer = this.data?.correct_answer ? this.data.correct_answer[0] : "";
  },
};
</script>
