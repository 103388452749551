<template>
  <v-row>
    <!-- Deskripsi -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Deskripsi </span>
      </div>
      <vue-editor
        :editorToolbar="customToolbar"
        v-model="data.description"
        class="overflow-y-auto"
        style="max-height: 400px"
        placeholder="Masukan deskripsi"
      ></vue-editor>
    </v-col>
    <!-- Boboboy -->
    <!-- <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Bobot Assesmen </span>
      </div>
      <v-text-field placeholder="Masukkan bobot assesmen"></v-text-field>
    </v-col> -->
  </v-row>
</template>

<script>
import { VueEditor } from "vue2-editor";
import model from "../_mixins/model";

export default {
  components: { VueEditor },
  mixins: [model],
  data: () => ({
    overlay: 0.1,
    overlay_color: "secondary",
    file: null,
  }),
  computed: {
    customToolbar() {
      return [];
    },
  },
  methods: {},
  created() {
    // console.log(this.data);

  }
};
</script>
