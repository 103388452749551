<template>
  <attachment-layout
    type="image"
    :src="images || {}"
    :selected="file[tab] || {}"
    :disabled="disabled"
    :saving="saving"
    :tab="1"
    :show.sync="show"
    :error="errors.message"
    :readonly="isReadOnly"
    @set="setAttachment"
    @open="fetchRecents"
    @close="close"
  >
    <template
      v-if="images.with_media === true"
      slot="preview"
    >
      <v-img
        width="250"
        height="auto"
        class="rounded mb-2"
        :src="images.media.public_url || src.media.url"
        :alt="images.alt"
      >
        <template slot="placeholder">
          <v-row class="fill-height" no-gutters align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="color lighten-3"
            ></v-progress-circular>
          </v-row> </template
      ></v-img>
      <template v-if="!disabled">
        <v-btn
          class="mr-2"
          x-small
          icon
          outlined
          elevation="0"
          @click="fetchRecents"
        >
          <v-icon>$replace</v-icon>
        </v-btn>
        <v-btn
          x-small
          icon
          outlined
          elevation="0"
          @click="$emit('update', { with_media: false , media:[] }); images.with_media=false; reset();"
        >
          <v-icon>$delete</v-icon>
        </v-btn>
      </template>
    </template>
    <template slot="tabs">
      <v-tabs v-model="tab" background-color="transparent">
        <v-tab class="text-capitalize"> Riwayat </v-tab>
        <!-- <v-tab class="text-capitalize"> URL gambar </v-tab> -->
        <v-tab class="text-capitalize"> Dari komputer </v-tab>
      </v-tabs>
    </template>
    <template slot="tab-items">
      <v-tabs-items class="transparent fill-height" v-model="tab">
        <v-tab-item>
          <v-card-text class="py-1">
            <v-list nav color="background" flat>
              <v-list-item-group
                mandatory
                active-class="bordered-2"
                v-model="selected"
              >
                <v-row>
                  <template v-if="loading">
                    <v-col cols="4">
                      <v-skeleton-loader type="image"></v-skeleton-loader>
                    </v-col>
                  </template>
                  <template v-else-if="recents.length">
                    <v-col v-for="recent in recents" :key="recent.id" cols="4">
                      <v-list-item class="pa-0">
                        <v-img
                          class="rounded"
                          :src="recent.public_url"
                          aspect-ratio="1"
                        >
                          <template slot="placeholder">
                            <v-row
                              class="fill-height"
                              no-gutters
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="color lighten-3"
                              ></v-progress-circular>
                            </v-row> </template
                        ></v-img>
                      </v-list-item>
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col> No recent media </v-col>
                  </template>
                </v-row>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-tab-item>
        <v-tab-item v-if="false">
          <v-card-text class="px-8 py-6">
            <v-row>
              <v-col>
                <div class="subtitle-2 mb-2">Masukkan URL</div>
                <v-text-field
                  outlined
                  filled
                  hide-details="auto"
                  v-model="url"
                  @change="setFile"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="loading">
              <v-col>
                <v-skeleton-loader type="image"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row v-else-if="!_.isEmpty(file[1]) && file[1].url != undefined">
              <v-col>
                <v-img class="rounded" :src="file[1].url"></v-img>
              </v-col>
            </v-row>
            <v-row v-else-if="errors">
              <v-col>
                <div class="text-center title color--text text--lighten-3">
                  {{ errors.message }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>
        <v-tab-item
          class="fill-height"
          style="position: relative"
          @dragover.prevent="draging = true"
          @dragleave="draging = false"
          @drop.prevent="setFile"
        >
          <v-card-text class="fill-height d-flex">
            <v-overlay
              absolute
              :value="true"
              :z-index="draging ? 5 : -1"
              :opacity="draging ? 0.46 : 0"
            />

            <input
              hidden
              ref="file"
              type="file"
              accept="image/*"
              @change="setFile"
            />
            <v-col v-if="_.isEmpty(file[1])" class="text-center my-auto">
              <div class="mb-8">
                <v-btn
                  color="primary"
                  elevation="0"
                  @click="$refs.file.click()"
                >
                  Pilih berkas
                </v-btn>
              </div>
              <div v-if="!draging" class="subtitle-1 mb-4">
                Atau tarik file ke sini
              </div>
              <div v-else class="subtitle-1 mb-r">Lepas file di sini</div>
            </v-col>

            <v-col v-else>
              <v-img class="rounded" :src="file[1].url">
                <v-col class="text-right">
                  <v-btn
                    color="white"
                    x-small
                    fab
                    elevation="0"
                    @click="$refs.file.click()"
                  >
                    <v-icon>$replace</v-icon>
                  </v-btn>
                </v-col>

                <template slot="placeholder">
                  <v-row
                    class="fill-height"
                    no-gutters
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="color lighten-3"
                    ></v-progress-circular>
                  </v-row> </template
              ></v-img>
            </v-col>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </attachment-layout>
</template>

<script>
import AttachmentLayout from "./AttachmentLayout.vue";
export default {
  name: "image-attachment",
  components: {
    AttachmentLayout,
  },
  props: {
    disabled: { type: Boolean, default: false },
    src: { type: Array },
  },
  data() {
    return {
      loading: false,
      saving: false,
      show: false,
      tab: 2,
      file: [],
      selected: null,
      url: "",
      recents: [],
      draging: false,
      errors: {},
      recent: {},
      images:{},
      id_img:null,
    };
  },
  computed: {
    isReadOnly() {
      return this.tab == 0 || this.loading || this.saving;
    },
  },
  created() {
    if (this.src != '') {
      this.fetchDetail(this.src)
    }
  },
  methods: {
    reset(){
      this.src='';
      this.images={}
    },
    fetchDetail(id) {
      if (id) {
        if (id[0]._id !=null) {
          this.id_img=id[0]._id
        } else {
          this.id_img=id;
        }
        this.$store.dispatch("media/detail", this.id_img).then((res) => {
          let file = { with_media: true, id:res.data.id, media: res.data };
          this.images=file;
          this.$emit("update", file);
        });
      }
    },
    fetchRecents() {
      this.loading = true;
      let page = 1 + this.recent.page || 0;
      this.$store
        .dispatch("media/list", { limit: 9, type: "image", page })
        .then((res) => {
          this.loading = false;
          this.recent = res.data;
          this.recents.push(...this.recent.list);
        });
      this.show = true;
    },
    setFile($event) {
      let vm = this;
      let src = {
        url: "",
        title: "",
        alt: "",
        description: "",
        caption: "",
      };

      switch (vm.tab) {
        // From recent
        case 0:
          Object.assign(src, this.recents[$event]);
          vm.$set(vm.file, vm.tab, src);
          break;
        // From file
        case 1:
          if (this.draging) {
            this.draging = false;
            src.url = URL.createObjectURL($event.dataTransfer.files[0]);
            src.title = $event.dataTransfer.files[0].name;
            src.alt = $event.dataTransfer.files[0].name;
            src.file = $event.dataTransfer.files[0];
          } else {
            src.url = URL.createObjectURL($event.target.files[0]);
            src.title = $event.target.files[0].name;
            src.alt = $event.target.files[0].name;
            src.file = $event.target.files[0];
          }
          src.source = "upload";

          vm.$set(vm.file, vm.tab, src);
          break;
          default:
          break;
      }
    },
    close() {
      this.$delete(this.file, 1);
      this.$delete(this.file, 2);
      this.show = false;
      this.recents = [];
      this.recent = {};
      this.selected = null;
      this.url = "";
    },
    async setAttachment(file) {
      if (this.tab) {
        this.saving = true;
        let formData = new FormData()
        formData.append('source',file.source)
        formData.append('alt',file.alt)
        formData.append('caption', file.caption)
        formData.append('description', file.description)
        formData.append('file', file.file)
        formData.append('title',`${file.title}`)
        formData.append('url', file.url)
        this.$store
          .dispatch("media/create", formData)
          .then((res) => {
            res.data.url = res.data.public_url
            file = { with_media: true, id:res.data.id, media: res.data };
            this.saving = false;
            this.$emit("update", file);
            this.fetchDetail(file.id)
            this.close();
          })
          .catch((error) => {
            this.errors.message = error.message;
            this.saving = false;
          });
      } else {
        file = { with_media: !this._.isEmpty(file), id:file.id, media: file };
        this.$emit("update", file);
        this.fetchDetail(file.id)
        this.close();
      }
    },
  },
  watch: {
    tab() {
      if (this.tab == 0) this.setFile(this.selected);
    },
    selected() {
      if (this.tab == 0) this.setFile(this.selected);
    },
  },
};
</script>

<style>
.v-application .bordered-2 {
  border: 2px solid;
  border-radius: 6px;
  border-color: var(--v-primary-base) !important;
}
</style>