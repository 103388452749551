import Vue from 'vue'
import VueRouter from 'vue-router'
import Views from '../views'
// import Home from '../views/Home'
// import Unauth from '../views/unauth'
import store from '../store'

Vue.use(VueRouter)

// const QuestionBank = [
// ]

let routes = {
  auth: [
    { path: '/dashboard', name: 'dashboard', component: Views.Dashboard },
    { path: '/program', name: 'list-program', component: Views.Program.List },
    { path: '/program/users/:id', name: 'list-user', component: Views.User.list },
    { path: '/program/tambah-program', name: 'add-program', component: Views.Program.Form },
    { path: '/program/edit/:id', name: 'edit-program', component: Views.Program.Detail },
    { path: '/update-password', name: 'update-password', component: Views.Auth.UpdatePassword },

    { path: '/program/:id', name: 'program-detail', component: Views.Programs.Detail },

    { path: '/program/:id/create', name: 'tna-create', component: Views.TnA.Create },
    // { path: '/activity/:id/add-act', name: 'tna-add', component: Views.TnA.AddAct },
    { path: '/program/:id/edit/:tnaId', name: 'tna-edit', component: Views.TnA.Edit },
    
    // Activity
    { path: '/program/:program/:topic/create', name: 'activity-create', component: Views.TnA.AddAct },
    { path: '/program/:program/:topic/:id/edit', name: 'activity-edit', component: Views.TnA.Edit },

    //certificate
    { path: '/certificate-participant/:id', name: 'certificate-participant', component: Views.Certif.CertifPart },
    { path: '/certificate-graduation/:id', name: 'certificate-graduation', component: Views.Certif.CertifGrad },
  
    //absensi
    { path: '/presensi', name: 'presensi', component: Views.Presensi.list },
    

  ],
  guest: [
    { path: '/', name: 'login', component: Views.Auth.Login },
    { path: '/forgot-password', name: 'forgot-password', component: Views.Auth.ForgotPassword },
    { path: '/password-reset', name: 'password-reset', component: Views.Auth.PasswordReset },
    { path: '/password-changed', name: 'password-changed', component: Views.Auth.PasswordChanged },
  ],
  default: [
    { path: '*', name: '404 Not Found', component: Views.Errors.NotFound },
  ]
}

routes.auth.forEach(route => { route.meta = { auth: true } });
routes.guest.forEach(route => { route.meta = { guest: true } });
routes = [
  ...routes.auth,
  ...routes.guest,
  ...routes.default,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == '/' && store.getters.isLoggedIn) {
    return next('/dashboard')
  }
  if (to.meta.auth && !store.getters.isLoggedIn) {
    sessionStorage.setItem('ytc_redirect', location.pathname)
    return next('/')
  }
  if (to.meta.guest && store.getters.isLoggedIn) {
    return next('/dashboard')
  }
  return next()
})

export default router
