import axios from "axios";
import url from "../store/api";
// const base_url = "http://prakerja.com/v1";

const SET_LIST = "SET_LIST";

function objectToFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (object[key] == undefined) {
      return;
    }
    if (!!object[key] && typeof object[key] == "object") {
      if (object[key] instanceof File) {
        formData.append(key, object[key], object[key].name);
      } else {
        for (let data of objectToFormData(object[key]).entries()) {
          const obindex = data[0].indexOf("[");
          const all = data[0].length;
          formData.append(`${key}[${data[0].slice(0, obindex == -1 ? all : obindex)}]${data[0].slice(obindex == -1 ? all : obindex)}`, data[1]);
        }
      }
    } else {
      const value = typeof object[key] == "boolean" ? (object[key] ? 1 : 0) : object[key];
      formData.append(key, value);
    }
  });
  return formData;
}

export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  getters: {},
  mutations: {
    SET_LIST: (state, payload) => {
      const index = state.list.findIndex((v) => v.topic == payload.topic);
      if (index != -1) state.list.splice(index, 1);
      state.list.push(payload);
    },
  },
  actions: {
    async fetch(context, payload) {
      const response = await axios
        .get(`${url.activity_list(payload)}`, { params: payload.params })
        .then((res) => res.data);
      context.commit(SET_LIST, { topic: payload.topic, data: response.data.list });
      return response;
    },
    async create(context, payload) {
      const formData = objectToFormData(payload);
      const config = payload.config || {}
      const response = await axios
        .post(`${url.activity_create(payload)}`, formData, config)
        .then((res) => res.data);
      return response;
    },
    async show(context, payload) {
      return await axios.get(`${url.activity_show(payload)}`).then((res) => res.data);
    },
    async edit(context, payload) {
      const formData = objectToFormData(payload.data);
      const config = payload.config || {}
      const response = await axios
        .post(`${url.activity_update(payload)}`, formData, config)
        .then((res) => res.data);
      return response;
    },
    async reorder(context, payload) {
      const response = await axios
        .put(`${url.activity_reorder(payload)}`, payload.data)
        .then((res) => res.data);
      return response;
    },
    async approve(context, payload) {
      return await axios.put(`${url.activity_approve(payload)}`).then((res) => res.data);
    },
    async delete(context, payload) {
      return await axios.delete(`${url.activity_delete(payload)}`).then((res) => res.data);
    },
  },
};
