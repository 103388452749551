<template>
  <v-row>
    <!-- Deskripsi -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Deskripsi </span>
      </div>
      <vue-editor
        :editorToolbar="customToolbar"
        v-model="data.description"
        class="overflow-y-auto"
        style="max-height: 400px"
        placeholder="Masukan deskripsi"
      ></vue-editor>
    </v-col>
    <!-- Link Youtube -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Link Youtube</span>
      </div>
      <div v-for="(yt, index) in list_youtube"
      :key="index">
        <div class="d-flex">
          <v-text-field v-model="yt.title" placeholder="Masukkan judul" class="mr-2 mb-2"></v-text-field>
          <v-text-field v-model="yt.url" placeholder="Masukkan url" class="mb-2"></v-text-field>
          <v-btn icon @click="list_youtube.splice(index, 1);"><v-icon>$delete</v-icon></v-btn>   
        </div>
      </div>
      <v-col cols="12">
        <v-btn outlined @click="list_youtube.push({})">+ tambah link youtube</v-btn>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { VueEditor } from "vue2-editor";
import model from "../_mixins/model";

export default {
  components: { VueEditor },
  mixins: [model],
  data: () => ({
    overlay: 0.1,
    overlay_color: "secondary",
    file: null,
    list_youtube:[],

  }),
  computed: {
    customToolbar() {
      return [];
    },
  },
  methods: {},
  watch: {
    list_youtube: {
      handler(val) {
        // this.$set(this.data, "meta_data", { ...this.data.meta_data, ...val });
        this.$set(this.data, "embed_youtube", val);
      },
      deep: true,
    },
  },
  created() {
    this.list_youtube = this.data?.embed_youtube ? this.data.embed_youtube : [{}];

  }
};
</script>
